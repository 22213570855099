<script setup lang="ts">
import { onMounted, ref, computed, nextTick } from "vue";
defineProps({
  showSearch: {
    type: Boolean,
    default: () => true
  }
});
const searchTool = ref(null);
const footer = ref(null);

const height = computed(() => {
  if (searchTool.value) {
    return `height:calc(100vh - ${footer?.value.offsetHeight}px - ${searchTool?.value?.offsetHeight}px - 130px)`;
  } else {
    return `height:calc(100vh - 260px)`;
  }
});
</script>

<template>
  <div>
    <div
      v-if="showSearch"
      ref="searchTool"
      class="bg-[#fff] dark:bg-[#000] px-[18px] pt-[18px] rounded-lg search_tool"
    >
      <el-row>
        <slot name="newTopBtn" />
      </el-row>
      <el-row>
        <slot name="search" />
        <el-form-item>
          <el-button type="primary" @click="$emit('searchConfirm')">
            查询
          </el-button>
          <el-button @click="$emit('searchReset')"> 重置 </el-button>
        </el-form-item>
      </el-row>
      <el-row>
        <slot name="newBtoBtn" />
      </el-row>
    </div>
    <div
      class="bg-[#fff] dark:bg-[#000] mt-4 rounded-lg overflow-hidden p-[18px]"
      :style="height"
    >
      <slot name="table" />
    </div>

    <div
      ref="footer"
      class="mt-[10px] text-right flex justify-end p-[15px] bg-[#fff] dark:bg-[#000] rounded-[10px] overflow-y-auto"
    >
      <slot name="footer" />
    </div>
  </div>
</template>
